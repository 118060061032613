import React, { useEffect, useState,useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Container, Form } from "react-bootstrap";
import { errorMsgs } from "../../constants/stringConstants";
import { Spinner } from "react-bootstrap";
import {
  Row,
  Col,
} from "react-bootstrap";
import MUIDataTable from "mui-datatables";
// import { DataGrid } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import PdfIcon from "@mui/icons-material/PictureAsPdfSharp";
import PdfIcon from "@mui/icons-material/PictureAsPdfOutlined";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import paidOutIcon from "../../assets/images/revamp/statement-paid-out-icon-red.svg";
import paidInIocn from "../../assets/images/revamp/statement-paid-out-icon.svg";
import CurrencyFlag from "react-currency-flags";
import { CiViewColumn } from "react-icons/ci";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useScreenSize } from "../../components/shared/ResizeHook";

import {
  getAllAccounts,
  getAccountStatement,
  getAccountStatementPagenated,
  getAccountStatementPdf,
} from "../../redux/toolkit/getAllUserAccount/operation";
import { AppDispatch, RootState } from "../../redux/store";
import { format } from "date-fns";

import "./index.css";
import { useDispatch } from "react-redux";

import ReactToPrintModal from "./previewReactToPrint";
import { Stack } from "@mui/material";
import { dataaaaa } from "./data";
import {
  SearchFilter,
  DownloadFilter,
  PrintFilter,
  FilterLines,
} from "../../constants/Images";
import BasicButton from "../../components/shared/Buttons/Basic";
import _ from 'lodash' 
import { GrFormNext, GrFormPrevious } from "react-icons/gr";


// eslint-disable-next-line @typescript-eslint/no-var-requires
const currencySymbolMap = require("currency-symbol-map");
// import { getSymbolFromCurrency } from 'currency-symbol-map';

//table one columns will be deleted after selecting table

//table one data will be deleted after selecting table

const Statement = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEditPreview, setIsOpenEditPreview] = useState(false);

  const accountId: any = localStorage.getItem("accountId");
  const language: any = localStorage.getItem("lng");
  const [accUUID, setAccUUID] = useState(accountId);
  const [accountData, setAccountData] = useState(null);
  const [accountDetails, setAccountDetails] = useState<any>([]);
  const accountIdSelecto: any = useSelector((state: RootState) => state.getAllAccounts.data);
  const [company, setCompany] = useState({});
  const [regNo, setRegNo] = useState("");
  const [statementData, setStatementData] = useState([]);
  const [statementFileData, setStatementFileData] = useState([]);
  const [stTableData, setStTableData] = useState<[] | any>([]);
  const [accountError, setAccountError] = useState("");
  const [table, setTable] = useState(false);
  const [inputTypeStartDate, setInputTypeStartDate] = useState("text");
  const [inputTypeEndDate, setInputTypeEndDate] = useState("text");
  const [totalPages, setTotalPages] = useState<number>();
  const [tableSearchText, setTableSearchText] = useState("");
  


  //Local storage
  const userId: any = localStorage.getItem("userId");

  const dispatch = useDispatch<AppDispatch>();
  const screenSize = useScreenSize();

  const CustomPagination = (props: { count: number, rowsPerPage: number, page: number, onChangePage: (newPage: number) => void }) => {
    const { count, rowsPerPage, page, onChangePage } = props;
  
    const handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
      getStatementData(newPage,false,tableSearchText)
      onChangePage(newPage - 1);
    };
  
    // Implement your custom rendering logic here
    return (
      <Pagination
        sx={{marginTop: "10px"}}
        className="custom-pagination-root-nav"
        color="primary"
        variant="outlined"
        shape="rounded"
        size={screenSize.width <= 500  ? "small" : "medium"}
        showFirstButton
        showLastButton
        count={Math.ceil(count / rowsPerPage)}
        page={page + 1}
        onChange={handlePageChange}
        renderItem={(item) => (
          <PaginationItem
            {...item}
            component="div"
            sx={{
              fontFamily: 'Montserrat',
              borderRadius:"7px",
              '&:hover:not(:active)': { backgroundColor: '#006FF4', color: '#FFFFFF' },
              "&.Mui-selected": {
                backgroundColor: "#006FF4",
                color: "white",
                borderColor: "transparent",
                // fontSize: 16,
                // height: "32px",
                // width: "32px",
                borderRadius: "6px"
              }
              
            }}
            // slots={{
            //   previous: () => <p style={{ margin: 0,fontSize:18 }}> &lt; </p>,
            //   next: () => <p style={{ margin: 0,fontSize:18 }}> &gt; </p>
            // }}
            slots={{
              previous: () => screenSize.width <= 768
                ? <GrFormPrevious width={"1em"} height={"1em"} fontSize={"1.15rem"} />
                : <p style={{ margin: 0 }}>{t("pagination.previous")}</p>,
              next: () => screenSize.width <= 768
                ? <GrFormNext width={"1em"} height={"1em"} fontSize={"1.15rem"} />
                : <p style={{ margin: 0 }}>{t("pagination.next")}</p>,
            }}
          />
        )}
      />
    );
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
          styleOverrides: {
            root: {
              padding: "10px",
              fontFamily: "Montserrat",
              fontSize: 14,
              // backgroundColor: '#CDCAC6',
            },
          },
        },
        MuiTypography: {
          styleOverrides: {
            root: {
              fontFamily: "Montserrat",
              fontSize: "18px",
              fontWeight: 500,
              color: "#ADB6C0",
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              fontFamily: "Montserrat",
              // fontSize:14,
              fontWeight: "bold",
              textAlign: "left",
              paddingLeft: 0,
            },
          },
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              fontWeight: "500 !important",
              color: "#231F20"
            },
          },
        },
        MuiPagination:{
          styleOverrides:{
            root:{
              justifyContent:'center',
              '& .MuiPagination-ul': { justifyContent:'center', padding:'5px'}
            }
          }
        },
        MUIDataTableFilter:{
          styleOverrides:{
            root:{fontFamily:'Montserrat'},
            reset:{
              // backgroundColor:"red",
              '& button': { 
                marginLeft:'10px',
                paddingLeft:"6px",
              }
            },
          }
        },
        MuiFormControl:{
          styleOverrides:{
            root:{
              padding:'35px 20px 20px 20px'
            }
          }
        },
        MUIDataTableToolbar:{
          styleOverrides:{
            root:{display:'flex'},
            left:{paddingTop:"0px"}
          }
        },        
        // MuiToolbar: {
        //   styleOverrides:{
        //     regular: {
        //     minHeight: '8px',
        //   }}
        // }
      },
    });

  const columns = [
    {
      name: "date",
      label: t("statement.table.date"),
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex: any) => {
          return (
            <div style={{ minWidth: "max-content" }}>
              {stTableData[dataIndex]?.date}
            </div>
          );
        },
      },
    },
    {
      name: "reference",
      label: t("statement.table.reference"),
      options: { filter: true, sort: false },
    },
    {
      name: "paidIn",
      label: t("statement.table.paidIn"),
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex: any) => {
          return (
            <div
              style={{ minWidth: "max-content", color: "#05D401", fontWeight: 600 }}
            >
              {stTableData[dataIndex]?.paidIn && (
                <img className="statement-paidIn-paidOut-icon" src={paidInIocn} style={{ marginRight: "5px" }} />
              )}
              {stTableData[dataIndex]?.paidIn}
            </div>
          );
        },
      },
    },
    {
      name: "paidOut",
      label: t("statement.table.paidOut"),
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex: any) => {
          return (
            <div
              style={{ minWidth: "max-content", color: "#F40F00", fontWeight: 600 }}
            >
              {stTableData[dataIndex]?.paidOut && (
                <img
                  src={paidOutIcon}
                  className="statement-paidIn-paidOut-icon"
                  style={{ marginRight: "5px", transform: "rotate(180deg)" }}
                />
              )}
              {stTableData[dataIndex]?.paidOut}
            </div>
          );
        },
      },
    },
    {
      name: "balance",
      label: t("statement.table.balance"),
      options: { filter: true, sort: false },
    },
    {
      name: "currency",
      label: t("statement.table.currency"),
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex: any) => {
          return (
            <div
              className="countryFlag"
              style={{ minWidth: "max-content", color: "#1A1A1A",display:'flex',alignItems:'center',gap:"3px" }}
            >
              {" "}
              {CurrencyFlagComponent(stTableData[dataIndex]?.currency)}{" "}
              {stTableData[dataIndex]?.currency}
            </div>
          );
        },
      },
    },
  ];
  const CurrencyFlagComponent = (logo: string) => (
    <CurrencyFlag currency={logo} width={21} />
  );

  // useEffects
  useEffect(() => {
    dispatch(getAllAccounts(userId));
  }, [dispatch]);

  useEffect(() => {
    if (accountIdSelecto) {
      console.log("selected account data is", accountIdSelecto);
      setAccountDetails(accountIdSelecto?.data?.data);
    }
  }, [accountIdSelecto, accountDetails]);

  //Mehtods
  const openModal = (event: any) => {
    event.preventDefault();
    // this.handleCalculateTotal()
    if (validate()) {
      setIsOpen(true);
      // if have any error
    } else {
      console.log("something went wrong");
    }
  };

  const generateStatementApi = () => {
    alert("write function");
  };

  const closeModal = (e: any) => {
    //when Edit Preview Modal is closed reset the form states
    if (isOpenEditPreview) {
      setStartDate("");
      setEndDate("");
      setIsOpenEditPreview(false);
    }
    setIsOpen(false);
  };

  const validate = () => {
    const isValid = [];
    if (startDate === "") {
      setStartDateError("errorMsgs.emptyDate");
      isValid.push(false);
    } else isValid.push(true);
    if (startDateError !== "") {
      setStartDateError(startDateError)
    } 

    if (endDate === "") {
      setEndDateError("errorMsgs.emptyDate");
      isValid.push(false);
    } else isValid.push(true);
    if (endDateError !== "") {
      setEndDateError(endDateError)
    }

    if (accountData === null) {
      setAccountError(errorMsgs.selectAccount);
      isValid.push(false);
    } else isValid.push(true);
    console.log(isValid);
    return isValid.every((e) => e === true);
  };

  const getStatementData = async (page:number,isLoading:boolean,search:any) => {
    setStatementData([]);
    // setStTableData([]);

    setLoading(isLoading && !search);
    if (validate()) {
      const payload = {
        startDate,
        endDate,
        accB4BUUID: accUUID,
        language,
        page:page,
        pageLimit:10,
        search:search ? search: ""
      };
        // const res: any = await dispatch(getAccountStatement(payload));
        const res: any = await dispatch(getAccountStatementPagenated(payload));
      if (res.payload != undefined) {
        const data = JSON.parse(JSON.stringify((res as any).payload?.data));
        const companyDet = JSON.parse(JSON.stringify((res as any).payload?.company));
        const regNumber = JSON.parse(JSON.stringify((res as any).payload?.regNumber));
        const pagination = JSON.parse(JSON.stringify((res as any).payload?.pagination));
        setTotalPages(pagination?.totalPages)
        const pdfData = data.map((d: any) => {
          return {
            date: format(new Date(d.created_at), "yyyy-MM-dd"),
            reference: (`${d.details ? d.details + ',' : ''}
            ${d.posting_key == 'cr' && d?.payer_name ? d?.payer_name + ',' : ''} 
            ${d.posting_key == 'cr' && d?.payer_account ? d?.payer_account + ',' : ''} 
            ${d.posting_key == 'cr' && d?.payer_scheme ? d?.payer_scheme + ',' : ''}
            ${d.posting_key == 'cr' && d?.reference?.includes('Payment Reversal') ? d?.reference + ',' : ''} 
            ${d.posting_key == 'dr' && d?.reference ? d?.reference + ',' : ''} 
            ${d.posting_key == 'dr' && d?.offsetting_transaction_entity ? d?.offsetting_transaction_entity + ',' : ''} 
            `).replace(/(\r\n|\n|\r|\s+)/gm, " ").trim(),
            postingKey: d.posting_key,
            amount: d.amount,
            newBalance: d.new_balance,
            currency: d.currency,
            payerName: d.payer_name || "", //for 'cr'
            payerAccount: d.payer_account || "", //for 'cr'
            payerScheme: d.payer_scheme || "", //for 'cr'
            offsettingType: d.offsetting_transaction_type || "", //for 'dr'
            offsettingEentity: d.offsetting_transaction_entity || "", //for 'dr'
          };
        });
        const tableData = data.map((d: any, index: number) => {
          return {
            id: index,
            date: format(new Date(d.created_at), "yyyy-MM-dd"),
            reference: ((`${d.details ? d.details + ',' : ''} 
                        ${d.posting_key == 'cr' && d?.payer_name ? d?.payer_name + ',' : ''} 
                        ${d.posting_key == 'cr' && d?.payer_account ? d?.payer_account + ',' : ''} 
                        ${d.posting_key == 'cr' && d?.payer_scheme ? d?.payer_scheme + ',' : ''}
                        ${d.posting_key == 'cr' && d?.reference?.includes('Payment Reversal') ? d?.reference + ',' : ''} 
                        ${d.posting_key == 'dr' && d?.reference ? d?.reference + ',' : ''} 
                        ${d.posting_key == 'dr' && d?.offsetting_transaction_entity ? d?.offsetting_transaction_entity + ',' : ''} 
                        `).replace(/(\r\n|\n|\r|\s+)/gm, " ").trim()).replace(/,+$/g, ''),
            paidIn: d.posting_key == 'cr' ? d.amount : '',
            paidOut: d.posting_key == 'dr' ? "-" + d.amount : '',
            balance: d.new_balance,
            currency: d.currency,
          };
        });
        setStTableData(tableData);
        // console.log("tableData =>>", tableData)
        // const modifiedArray = tableData.map(({ id, ...rest }: { id: number; }) => rest);
        setStatementData(pdfData);
        // setStatementData(dataaaaa); //uncomment this to use hard coded data to test 47 pages pdf
        setCompany(companyDet);
        setRegNo(regNumber);
        setStatementFileData(res?.payload?.pdfBytes);
      }
    }
    setLoading(false);
  };

  const getData = async () => {
    if (validate()) {
      const payload = {
        startDate: startDate,
        endDate: endDate,
        accB4BUUID: accUUID,
        language,
        search: ''
      };
      const res: any = await dispatch(getAccountStatementPdf(payload));
      if (res.payload) {
        const data = JSON.parse(JSON.stringify((res as any).payload?.data));
        const tableData = data?.map((d: any, index: number) => {
          return {
            date: format(new Date(d.created_at), "yyyy-MM-dd"),
            reference: (`${d.details ? d.details + ',' : ''}
                      ${d.posting_key == 'cr' && d?.payer_name ? d?.payer_name + ',' : ''} 
                      ${d.posting_key == 'cr' && d?.payer_account ? d?.payer_account + ',' : ''} 
                      ${d.posting_key == 'cr' && d?.payer_scheme ? d?.payer_scheme + ',' : ''}
                      ${d.posting_key == 'cr' && d?.reference?.includes('Payment Reversal') ? d?.reference + ',' : ''} 
                      ${d.posting_key == 'dr' && d?.reference ? d?.reference + ',' : ''} 
                      ${d.posting_key == 'dr' && d?.offsetting_transaction_entity ? d?.offsetting_transaction_entity + ',' : ''} 
                      `).replace(/(\r\n|\n|\r|\s+)/gm, " ").trim(),
            postingKey: d.posting_key,
            amount: d.amount,
            newBalance: d.new_balance,
            currency: d.currency,
            payerName: d.payer_name || "", //for 'cr'
            payerAccount: d.payer_account || "", //for 'cr'
            payerScheme: d.payer_scheme || "", //for 'cr'
            offsettingType: d.offsetting_transaction_type || "", //for 'dr'
            offsettingEentity: d.offsetting_transaction_entity || "", //for 'dr'
          };
        });
        const convertedArray = tableData.map((item: any, index: number) => {
          return {
            data: [
              item.date,
              item.reference.replace(/,+$/, ''), // Removing trailing commas
              item.postingKey === 'cr' ? item.amount : '',
              item.postingKey === 'dr' ? -item.amount : '',
              item.newBalance,
              item.currency
            ],
          };
        });
        return convertedArray;
      }
    }
  }

  const accountChangeHandler = async (e: any) => {
    const accountDa = JSON.parse(e.target.value);
    setAccountData(accountDa);
    setAccUUID(accountDa.accB4BUUID);
    setAccountError("");
  };

  const downloadStatementPDFBytes = () => {
    try {
      const pdfBytes = statementFileData;

      if (pdfBytes) {
        if (typeof pdfBytes === "string") {
          const uint8Array = Uint8Array.from(atob(pdfBytes), (c) =>
            c.charCodeAt(0)
          );
          const blob = new Blob([uint8Array], { type: "application/pdf" });

          const a = document.createElement("a");
          a.href = window.URL.createObjectURL(blob);
          a.download = "receipt.pdf";
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          console.error("pdfBytes is not a string:", pdfBytes);
        }
      } else {
        console.error("pdfBytes is undefined or null");
      }
    } catch (error) {
      console.error("Error Occurred: ", error);
    }
  };

  const CustomPdfIcon = () => {
    return (
      <>
        <Tooltip title="Download Statement">
        <span>
          <IconButton sx ={{color:"#231f20"}}
            disabled={statementData?.length ? false : true}
            onClick={downloadStatementPDFBytes}
            // sx={{ "&:hover": { color: "#231f20" } }}
          >
            <PdfIcon />
          </IconButton>
          </span>
        </Tooltip>
      </>
    );
  };
  const renderIcon = (icon: string) => <img src={icon} alt="" />;

  const customIcons: any = {
    SearchIcon: () => renderIcon(SearchFilter),
    PrintIcon: () => renderIcon(PrintFilter),
    DownloadIcon: () => renderIcon(DownloadFilter),
    ViewColumnIcon: () => (
      <CiViewColumn style={{ color: "black", fontSize: "larger" }} />
    ),
    FilterIcon: () => renderIcon(FilterLines),
  };
  const handleStartDateChange = (e:any) => {
    const selectedDate = e.target.value;
    const minDate = new Date('1900-01-01');
    const maxDate = new Date();
    const selectedDateObj = new Date(selectedDate);

    if (selectedDateObj < minDate) {
      setStartDateError('errorMsgs.startDatelimit');
    } else if (selectedDateObj > maxDate) {
      setStartDateError('errorMsgs.endDatelimit');
    } else {
      setStartDateError('');
      setStartDate(selectedDate);
    }

  };
  const handleEndDateChange = (e:any) => {
    const selectedDate = e.target.value;
    const minDate = new Date('1900-01-01');
    const maxDate = new Date();
    const selectedDateObj = new Date(selectedDate);

    if (selectedDateObj < minDate) {
      setEndDateError('errorMsgs.startDatelimit');
    } else if (selectedDateObj > maxDate) {
      setEndDateError('errorMsgs.endDatelimit');
    } else {
      setEndDateError('');
      setEndDate(selectedDate);
    }

  };
  const dataTableSearchChange=(searchText:any)=>{
    debouncedSave(searchText)
    setTableSearchText(searchText)

  }
  const debouncedSave = useCallback(
    _.debounce(
      (value:string) =>
        getStatementData(1,false,value?.trim()),

      600,
    ),
    [startDate, endDate, accountData], // will be created only once initially
  );

  return (
    <Row className="statement-root-row">
      <Container fluid>
        <Row className="justify-content-end date-selector-root-row">
          <Col xs={3} sm={12} md={4} lg={3} xl={2} className="date-selector-root-col">
            {/* <div className="d-flex flex-row align-items-center"> */}
            <span style={{fontWeight: 600}} className="d-block span-dates">
                          {t("statement.form.startDate")}
                        </span>
            <Form.Control
              className="date_input_style"
              type="date"
              id="startDate"
              // type={inputTypeStartDate}
              // value={startDate}
              name={"startDate"}
              required
              placeholder="Start Date"
              max={new Date().toISOString().split('T')[0]}
              min="1900-01-01"
              // onFocus={() => setInputTypeStartDate("date")}
              // onBlur={() => {
              //   setInputTypeStartDate("text")
              //   if(startDate!=='') setInputTypeStartDate('date')
              // }}
              // onChange={(e: any) => {
              //   setStartDateError("");
              //   setStartDate(e.target.value);
              // }}
              onBlur={handleStartDateChange}
            />
            {/* </div> */}
            <span className="errorMsg">{t(`${startDateError}`)}</span>
          </Col>
          <Col xs={3} sm={12} md={4} lg={3} xl={2} className="date-selector-root-col">
            {/* <div className="d-flex flex-row align-items-center"> */}
            <span style={{fontWeight: 600}} className="d-block span-dates">
                  {t("statement.form.endDate")}
              </span>

            <Form.Control
              className="date_input_style"
              type="date"
              // type={inputTypeEndDate}
              // value={endDate}
              name={"endDate"}
              placeholder="End Date"
              required
              min="1900-01-01"
              max={new Date().toISOString().split('T')[0]}
              // onFocus={() => setInputTypeEndDate("date")}
              // onBlur={() => {
              //   setInputTypeEndDate("text")
              //   if(endDate!=='') setInputTypeEndDate('date')
              // }}
              // onChange={(e: any) => {
              //   setEndDateError("");
              //   setEndDate(e.target.value);
              // }}
              onBlur={handleEndDateChange}
            />
            {/* </div> */}
            <span className="errorMsg">{t(`${endDateError}`)}</span>
            
            
          </Col>
          <Col xs={3} sm={12} md={4} lg={3} xl={2} className="date-selector-root-col">
            {/* <div className="d-flex flex-row align-items-center"> */}
            <span style={{fontWeight: 600}} className="d-block span-dates">
                  {t("statement.form.account")}
            </span>
            <Form.Group style={{ borderRadius: "10px" }}>
              <Form.Select
                onChange={(e: any) => accountChangeHandler(e)}
                className="btn-account-selector"
                aria-label="Change Currency"
                style={{
                  border: "1px solid #D7F0FF",
                  height: "40px",
                  textAlign: "left",
                  fontSize: "14px",
                  whiteSpace:'nowrap',
                  overflow:'hidden',
                  textOverflow:'ellipsis'
                 
                  
                }}
              >
                <option value="" disabled selected hidden>
                  {t("convert.form.selectedAcc")}
                </option>
                {accountDetails &&
                  accountDetails.map((ele: any, index: number) =>
                    !ele.inactive ? (
                      <option key={index} value={JSON.stringify(ele)}>
                        {ele.accountName}
                      </option>
                    ) : null
                  )}
              </Form.Select>
              <span className="errorMsg">{accountError}</span>
            </Form.Group>
            {/* </div> */}
          </Col>
          <Col xs={3} sm={12} md={4} lg={3} xl={2} className="date-selector-root-col">
          <span className="d-block span-dates" style={{visibility:"hidden",fontWeight: 600}}>
              {t("statement.form.account")}
          </span>
            <BasicButton
              sx={{height:'39px'}}
              text={t("statement.form.search")}
              type="submit"
              isLoading={loading}
              onClick={()=>getStatementData(1,true,tableSearchText)}
            />
          </Col>
        </Row>
      </Container>
      <Col
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className="mt-3 statement-SectionBackground"
      >
        {/* {!table &&  */}
        <div className="statementTable mui-datatable-container">
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title={
                <p className="mb-0 statementTblHeading">
                  {t("statement.title")}
                </p>
              }
              data={stTableData}
              columns={columns}
              components={{ icons: customIcons }}
              options={{
                serverSide:true,
                selectableRowsHideCheckboxes: true,
                selectableRowsOnClick: false,
                
                onDownload: (buildHead, buildBody, columns, data) => {
                  // default return type
                  // return "\uFEFF" + buildHead(columns) + buildBody(data); 

                  (async () => {
                    try {
                      const fullData = await getData();
                      const csvData = fullData && fullData.length > 0 ? fullData : data;
                      const csvContent = "\uFEFF" + buildHead(columns) + buildBody(csvData);
                      // Trigger the download
                      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
                      const link = document.createElement('a');
                      link.href = URL.createObjectURL(blob);
                      link.setAttribute('download', 'Statement.csv');
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    } catch (error) {
                      console.error("Failed to fetch data: ", error);
                    }
                  })();
                
                  // Return false to prevent default download behavior
                  return false;
                },
                // fixedHeader:true
                responsive: "standard",
                customToolbar: () => <CustomPdfIcon />,
                textLabels: {
                  body: {
                    noMatch: "Sorry, no matching records found",
                  },
                
                },
                onSearchChange:(searchText)=>{dataTableSearchChange(searchText)},
                customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => (
                  <CustomPagination
                    // count={count} //without pagination
                    count={totalPages ? totalPages * 10 : count}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={changePage}
                  />
                )

              }}
            />
          </ThemeProvider>
        </div>
        {/* } */}
        <div className="btnViewStatement mt-2 mb-3">
       

        </div>
        
      </Col>
      <Col
        md={12}
        lg={12}
        xl={12}
        className="d-flex justify-content-center"
        style={{background:"#ffffff", borderBottomLeftRadius:'15px', borderBottomRightRadius:"15px", paddingBottom:'20px'}}
      >
        <div className="btnViewStatement">
          <BasicButton
            type="submit"
            onClick={openModal}
            text={isOpen ? <Spinner animation={"border"} /> :  t("statement.form.viewStatementBtn")}
            disabled={statementData.length ? false : true}
          />
          <ReactToPrintModal
            showModal={isOpen}
            closeModal={closeModal}
            pdfData={{
              tableData: statementData,
              startDate: startDate,
              endDate: endDate,
              accountData: accountData,
              company: company,
              regNo: regNo,
              pdfBytes: statementFileData,
              accUUID,
              paidInIocn,
              paidOutIcon,
              search : tableSearchText
            }}
            isOpenEditPreview={isOpenEditPreview}
            onGenerateInvoice={generateStatementApi}
            generateInvoiceLoading={loading}
            isFile={true}
          />
        </div>
      </Col>
    </Row>
  );
};

export default Statement;
