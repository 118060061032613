import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MUIDataTable, { MUIDataTableColumnDef } from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { format, isValid } from "date-fns";
import COLORS from "../../../utils/colors";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const currencySymbolMap = require("currency-symbol-map");
// import { getSymbolFromCurrency } from 'currency-symbol-map';
// import { updatePaymentStatus } from "../../../redux/toolkit/sendFunds/operations";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { Spinner, Modal } from "react-bootstrap";
import InputTypeText from "../../../components/shared/Input/Basic/InputTypeText";
import BasicButton from "../../../components/shared/Buttons/Basic";
import ModalHeader from "../../../components/shared/Modal/ModalHeader";
import Card from "react-bootstrap/Card";
import { Row, Col } from "react-bootstrap";
import { dataaaaa } from "./data";
import "./index.css";
import {
  SearchFilter,
  DownloadFilter,
  FilterLines,
  PrintFilter,
  DeleteBlackIcon,
  EditPenIcon,
  ViewDocIcon,
  ConfimationImage,
} from "../../../constants/Images";
import { CiViewColumn } from "react-icons/ci";
import ToggleSwitch from "../ToggleButton";
import CustomPagination from "../CustomPagination";
import FundsTransferUpdateForm from "../../../pages/SendFunds/FundTransferUpdateForm";
import Accordion from "react-bootstrap/Accordion";
import { PaymentVerifictionIcon } from "../../../constants/Images";

import SmsIcon from "@mui/icons-material/Sms";
import OtpInput from "../../../components/shared/OTP";
import CallIcon from "@mui/icons-material/Call";
import CustomIconButton from "../../../components/shared/Buttons/IconButton";
import TransactionDetail from "../TransactionDetail";
import { IconButton, Tooltip, Box } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import {
  OTPVerification,
  SendOTP,
  SendOTPVoice,
} from "../../../redux/toolkit/auth/operation";
import Swal from "sweetalert2";

export const CustomDataTable = ({
  data,
  updatePaymentStatus,
  getRecPayments,
  deletePayment,
  resendCustomFunds,
}: any) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [code, setCode] = useState("");
  const [loading2, setLoading2] = useState(false);
  const [twofaErr, setTwofaErr] = useState("");
  const [show2fa, setShow2fa] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const dispatch = useDispatch<AppDispatch>();
  const toggle2faHandle = () => setShow2fa(!show2fa);
  const userId: any = localStorage.getItem("userId");
  const roles: any = localStorage.getItem("roles");

  const userPhoneNo: any = localStorage.getItem("userPhone");
  const [loadingOTP, setLoadingOTP] = useState(false);
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [otpBtnText, setOtpBtnText] = useState("Send");
  const [otp, setOtp] = useState("");
  const onChange = (value: string) => setOtp(value);
  const [loadingOTPVerify, setLoadingOTPVerify] = useState(false);
  const [otpErrorMsg, setOtpErrorMsg] = useState("");
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [dataDetail, setDataDetail] = useState({});
  const [detailsShow, setDetailsShow] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState([
    { date: "", status: "" },
  ]);
  const [selectedId, setSelectedId] = useState("");
  const [retryLoading, setRetryLoading] = useState(false);

  const handleCloseDetails = () => {
    setDetailsShow(false);
  };

  const customResendFunc = async (date: string) => {
    try {
      await resendCustomFunds({id: selectedId, date});
      setDetailsShow(false);
    } catch (error) {
      console.log("... error ", error);
    }
  };

  const detailsTheme = createTheme({
    components: {
      MuiTableHead: {
        styleOverrides: {
          root: {
            "& .MuiTableRow-root": {
              height: "40px !important", // Set the height for header rows
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: "none", // Removes shadow
            border: "none", // Removes border
          },
        },
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            height: "20px",
            backgroundColor: "#006FF4",
            color: "white",
            fontWeight: "bold",
            fontFamily: "Montserrat",
          },
        },
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            // height: "20px",
            // padding: "5px 16px !important",
            fontFamily: "Montserrat !important",
            // display: "flex",
            fontSize: "16px",
          },
        },
      },
      MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "6px 16px !important", // Overriding padding for table cell
        },
      },
    },
    },
  });

  const beneficiariesHeaders: MUIDataTableColumnDef[] = [
    {
      name: "date",
      label: t("paymentHistoryModal.date") as string,
      options: {
        setCellHeaderProps: () => ({
          style: { padding: "0px 16px", color: "white" },
        }),
        // setCellProps: () => ({
        //     style: { textAlign: "left" },
        //   }),

        customBodyRenderLite: (dataIndex: any) => {
          return (
            <>
              <div>{selectedDetail[dataIndex]?.date}</div>
            </>
          );
        },
      },
    },
    {
      name: "status",
      label: t("paymentHistoryModal.status") as string,
      options: {
        setCellHeaderProps: () => ({
          style: { padding: "0px 16px", color: "white" },
        }),
        // setCellProps: () => ({
        //     style: { textAlign: "left" },
        //   }),
        customBodyRenderLite: (dataIndex: any) => {
          return (
            <>
              <div>{selectedDetail[dataIndex]?.status}</div>
            </>
          );
        },
      },
    },
    // {
    //   name: "sendDate", label: t("paymentHistoryModal.sendDate") as string, options: {
    //     setCellHeaderProps: () => ({
    //         style: { padding: "0", color: "white" }
    //       }),
    //     customBodyRenderLite: (dataIndex: any) => {
    //       return <><div>{deatilsState[dataIndex]?.sendDate}</div></>
    //     }
    //   }
    // },
    {
      name: "actions",
      label: t("paymentReceiver.seeAllModal.actions") as string,
      options: {
        setCellHeaderProps: () => ({
          style: { padding: "0px 16px", color: "white"},
        }),
        // setCellProps: () => ({
        //     style: {  textAlign: "left" },
        //   }),
        customBodyRenderLite: (dataIndex: any) => {
          return (
            <>
              <button
                disabled={
                  selectedDetail[dataIndex]?.status?.toLocaleLowerCase() ===
                  "failed"
                    ? false
                    : true
                }

                type="button"
                className="btn btn-sm btn-outline-primary"
                onClick={(e) => {
                  e.stopPropagation();
                  setRetryLoading(true);
                  console.log('... ye ', selectedDetail[dataIndex]?.date)
                  // resendCustomFunds(selectedId);
                  customResendFunc(selectedDetail[dataIndex]?.date).finally(() => setRetryLoading(false));
                }}
                style={{
                    backgroundColor: selectedDetail[dataIndex]?.status?.toLocaleLowerCase() === "failed" 
                      ? "#006FF4" 
                      : "#1976d2",
                    color: "#fff", 
                    borderColor: selectedDetail[dataIndex]?.status?.toLocaleLowerCase() === "failed" 
                      ? "#006FF4" 
                      : "#1976d2",
                  }}
              >
                {retryLoading ? (
        <Spinner animation="border" size="sm" style={{ color: "#fff" }} /> 
    ) : (
        t("paymentHistoryModal.retry")
    )}

              </button>
            </>
          );
        },
      },
    },
  ];

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
          styleOverrides: {
            root: {
              padding: "10px",
              fontFamily: "Montserrat",
              fontSize: 18,
              // backgroundColor: '#CDCAC6',
            },
          },
        },
        MuiTypography: {
          styleOverrides: {
            root: {
              fontFamily: "Montserrat",
              fontSize: "18px",
              fontWeight: 500,
              color: "#ADB6C0",
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              fontFamily: "Montserrat",
              // fontSize:14,
              fontWeight: "600",
              textAlign: "left",
              paddingLeft: 0,
            },
          },
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              fontWeight: "500 !important",
            },
          },
        },
        MuiToolbar: {
          styleOverrides: {
            root: {
              display: "flex",
            },
          },
        },
        MuiList: {
          styleOverrides: {
            root: {
              display: "flex",
              flexDirection: "column",
              background: "#ffffff",
            },
          },
        },
        MUIDataTableFilter: {
          styleOverrides: {
            root: { fontFamily: "Montserrat" },
            reset: {
              // backgroundColor:"red",
              "& button": {
                marginLeft: "10px",
                paddingLeft: "6px",
              },
            },
          },
        },
        MuiFormControl: {
          styleOverrides: {
            root: {
              padding: "35px 20px 20px 20px",
            },
          },
        },
      },
    });

  const columns = [
    {
      name: "recurringDate",
      label: t("recurringPayments.dateAndDay"),
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex: any) => {
          const days = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];
          let date: any = new Date(data[dataIndex]?.recurringDate);
          if (data[dataIndex]?.recurringType === "weekly") {
            date = days[date.getDay()];
          } else date = format(date, "yyyy-MM-dd");

          return <div style={{ minWidth: "max-content" }}>{date}</div>;
        },
      },
    },
    {
      name: "beneficiary_name",
      label: t("recurringPayments.beneficiary"),
      options: { filter: true, sort: false },
    },
    {
      name: "beneficiary_iban",
      label: "IBAN",
      options: { filter: true, sort: false },
    },
    {
      name: "payment_reference",
      label: t("recurringPayments.paymentReference"),
      options: { filter: true, sort: false },
    },
    {
      name: "payment_amount",
      label: t("recurringPayments.amount"),
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex: any) => {
          return (
            <div style={{ minWidth: "55px" }}>
              {data[dataIndex]?.payment_amount}
            </div>
          );
        },
      },
    },
    {
      name: "payment_currency",
      label: t("recurringPayments.currency"),
      options: { filter: true, sort: false },
    },
    {
      name: "recurringType",
      label: t("recurringPayments.recurringType"),
      options: { filter: true, sort: false },
    },
    {
      name: "description",
      label: t("recurringPayments.description"),
      options: { filter: true, sort: false },
    },
    // {
    //     name: "status",
    //     label: "Status",
    //     options: { filter: true, sort: false },
    // },
    {
      name: "payment_amount",
      label: t("recurringPayments.action"),
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex: any) => {
          return (
            <div onClick={(event) => event.stopPropagation()}>
              <Box display="flex" alignItems="center">
                <ToggleSwitch
                  onChangeHandler={() => {
                    // event.stopPropagation();
                    // startStopHandler(data[dataIndex]);
                    // setCurrentRecord(data[dataIndex]);
                    // setShow2fa(true);
                    if (data[dataIndex]?.is2FAVerified === false) {
                      setCurrentRecord(data[dataIndex]);
                      setShow2fa(true);
                    } else {
                      startStopHandler(data[dataIndex]);
                    }
                  }}
                  isEnable={data[dataIndex]?.isRecurring ? true : false}
                />
                {/* details button */}
                {data[dataIndex]?.recurringStatus &&
                data[dataIndex]?.recurringStatus?.length > 0 ? (
                  <IconButton
                    onClick={(event) => {
                      event.stopPropagation();
                      setDetailsShow(true);
                      setSelectedDetail(data[dataIndex]?.recurringStatus);
                      setSelectedId(data[dataIndex]?._id);
                    }}
                  >
                    <Tooltip title={t("bankAccounts.muiDataTable.tableBodyIconToolTips.details") }>
                      <img src={ViewDocIcon} />
                    </Tooltip>
                  </IconButton>
                ) : null}
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    setShowUpdateForm(true);
                    setSelectedRecord(data[dataIndex]);
                  }}
                >
                  <Tooltip title={t("bankAccounts.muiDataTable.tableBodyIconToolTips.edit") }>
                    {/* <EditIcon sx={{color: "lightseagreen"}}/> */}
                    <img src={EditPenIcon} />
                  </Tooltip>
                </IconButton>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    deleteHandler(data[dataIndex]?._id);
                  }}
                >
                  <Tooltip title={t("bankAccounts.muiDataTable.tableBodyIconToolTips.delete") }>
                    {/* <DeleteIcon sx={{ color: "red" }} /> */}
                    <img
                      src={DeleteBlackIcon}
                      style={{
                        filter:
                          "invert(31%) sepia(78%) saturate(747%) hue-rotate(193deg) brightness(97%) contrast(98%)",
                      }}
                    />
                  </Tooltip>
                </IconButton>
              </Box>
              {/* <IconButton onClick={(event) => {
                            setIsShowDetail(true);
                            setDataDetail(data[dataIndex]);
                        }}>
                            <Tooltip title={""}>
                                <VisibilityIcon sx={{
                                    color: "#006FF4",
                                }} />
                            </Tooltip>
                        </IconButton> */}
            </div>
          );
          // <button
          //     style={{ background: !data[dataIndex]?.isRecurring ? COLORS.pimary : "#f40f00", color: '#ffffff', padding: '10px', borderRadius: '8px', border: 'none',cursor:isLoading ? "":"pointer" }}
          //     onClick={() => startStopHandler(data[dataIndex])}
          //     disabled={isLoading}
          // >
          //     {/* {(isLoading && data[dataIndex]) && <Spinner animation={"border"} size="sm" style={{marginRight:"5px"}}/> } */}
          //     { !data[dataIndex]?.isRecurring ?  "Start" : "Stop"}

          // </button>
        },
      },
    },
  ];

  const handleSendOTPButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    setLoadingOTP(true);
    const payload = {
      phoneNo: userPhoneNo,
    };
    dispatch(SendOTP(payload)).then(() => {
      setLoadingOTP(false);
      setShowOTPInput(true);
      setOtpBtnText("Resend");
    });
  };

  const verifyOTPLengthOnBlur = (e: any) => {
    if (e.length < 6) {
      setOtpErrorMsg("Enter all otp digits");
    } else {
      setOtpErrorMsg("");
    }
  };

  const deleteHandler = async (id: string) => {
    try {
      Swal.fire({
        imageUrl: ConfimationImage,
        title: `${t("sweetAlerts.areYouSure")}`,
        text: `${t("sweetAlerts.deleteRecord")}`,
        // icon: "question",
        showCancelButton: true,
        confirmButtonText: `${t("sweetAlerts.yes")}`,
        cancelButtonText: `${t("sweetAlerts.no")}`,
        customClass: {
          confirmButton: "modalBtnOK",
          cancelButton: "modalBtnCancel",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          setIsLoading(true);
          const res = await dispatch(deletePayment({ id }));
          await getRecPayments();
        } else {
          // User clicked "No" or closed the SweetAlert popup
          // Handle the cancellation or any other action you want
        }
      });
    } catch (error) {
      console.log("... error ", error);
    }
    setIsLoading(false);
  };

  const startStopHandler = async (data: any) => {
    try {
      setIsLoading(true);
      // console.log("startStopHandler =>", data);
      const payload = {
        id: data._id,
        status: !data.isRecurring,
        userId: userId,
        authCode: data?.is2FAVerified ? "ADMIN" : otp || code,
      };
      const res = await dispatch(updatePaymentStatus(payload));
      await getRecPayments();
      // console.log(res)
      setIsLoading(false);
      setShow2fa(false);
    } catch (error) {
      setIsLoading(false);
    }
    setCode("");
    setOtp("");
    setShowOTPInput(false);
  };

  const renderIcon = (icon: string) => <img src={icon} alt="" />;
  const customIcons: any = {
    SearchIcon: () => renderIcon(SearchFilter),
    PrintIcon: () => renderIcon(PrintFilter),
    DownloadIcon: () => renderIcon(DownloadFilter),
    ViewColumnIcon: () => (
      <CiViewColumn style={{ color: "black", fontSize: "larger" }} />
    ),
    FilterIcon: () => renderIcon(FilterLines),
  };

  return (
    <>
      <TransactionDetail isShow={isShowDetail} data={dataDetail} />
      {/* 2FA Modal */}
      <Modal
        show={show2fa}
        onHide={toggle2faHandle}
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader onClose={() => setShow2fa(false)} />
        <Modal.Body className="sendFund-2FA-modal">
          <div className="d-flex justify-content-center align-items-center flex-column">
            <div className="mb-2">
              <img src={PaymentVerifictionIcon} width="100" height="100" />
            </div>
            <div className="paymentVerifyText mb-3">
              <h2 className="ver-right-title m-0">
                {t("sendFunds.modal.title")}
              </h2>
            </div>
          </div>

          <Accordion>
            <Accordion.Item eventKey="0" className="p-0 accordionTab">
              <Accordion.Header>
                Verify 2FA from Authenticator App
              </Accordion.Header>
              <Accordion.Body>
                <form
                  className="verification-form"
                  // onSubmit={handleVerifyPaymentUpdated}
                >
                  <InputTypeText
                    placeholder="Enter code"
                    onChange={(e) => {
                      setCode(e.target.value);
                      setTwofaErr("");
                    }}
                    errorMsg={twofaErr}
                  />
                  <BasicButton
                    onClick={() => {
                      startStopHandler(currentRecord);
                    }}
                    // type="submit"
                    text="Send"
                    isLoading={isLoading ? true : false}
                  />
                </form>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion>
            <Accordion.Item eventKey="0" className="mt-4 p-0 accordionTab">
              <Accordion.Header>{t("verify.otp.description")}</Accordion.Header>
              <Accordion.Body>
                <div className="mr-xl-1">
                  <p>We will send OTP to your registered number</p>
                </div>

                <div className="mr-xl-1">
                  <CustomIconButton
                    text={
                      loadingOTP ? (
                        <>
                          {" "}
                          Sending.. <Spinner animation={"border"} />
                        </>
                      ) : (
                        `${otpBtnText} ${userPhoneNo}`
                      )
                    }
                    isLoading={loadingOTP ? true : false}
                    disabled={loadingOTP ? true : false}
                    onClick={handleSendOTPButtonClick}
                    suffixIcon={<SmsIcon />}
                  />
                </div>

                {showOTPInput ? (
                  <>
                    <div
                      style={{
                        alignContent: "center",
                        width: "100%",
                        padding: 10,
                      }}
                    >
                      <OtpInput
                        value={otp}
                        valueLength={6}
                        onChange={onChange}
                        onBlur={verifyOTPLengthOnBlur}
                      />
                      <div className="mr-xl-1">
                        <BasicButton
                          text={isLoading ? "Verifying" : "Verify"}
                          className="mt-2"
                          disabled={isLoading ? true : false}
                          onClick={() => startStopHandler(currentRecord)}
                          isLoading={isLoading ? true : false}
                        />
                      </div>
                    </div>
                    <div className="mr-xl-1">
                      {/* <div
                                onClick={handleSendOTPVoiceButtonClick}
                                style={{ fontSize: 13 }}
                            >
                                {" "}
                                Having trouble Receiving SMS,{" "}
                                {loadingOTPVoice ? (
                                <Spinner
                                    style={{ width: 15, height: 15 }}
                                    animation={"border"}
                                />
                                ) : (
                                <CallIcon
                                    color="primary"
                                    style={{ cursor: "pointer" }}
                                />
                                )}{" "}
                                call me instead.
                            </div> */}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Modal.Body>
      </Modal>
      <Modal
        show={showUpdateForm}
        onHide={() => setShowUpdateForm(!showUpdateForm)}
        backdrop="static"
        keyboard={false}
        size="lg"
        // className={show2FA ? 'd-none' : 'd-block'}
      >
        <ModalHeader
          title={t("sendFunds.title")}
          onClose={() => setShowUpdateForm(!showUpdateForm)}
        />
        <Modal.Body
        // ref={trandferMoneyModalRef}
        >
          <FundsTransferUpdateForm
            record={selectedRecord}
            getRecPayments={getRecPayments}
            onClose={() => setShowUpdateForm(!showUpdateForm)}
          />
        </Modal.Body>
      </Modal>
      {/* <Row> */}
      {/* Content area collomns */}
      {/* <Col sm={12} md={12} lg={12}> */}
      {/* <Card className="p-3 p-xl-5 my-3 my-xl-4"> */}
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={
            <p className="mb-0 recuringTbl">{t("recurringPayments.title")}</p>
          }
          data={data}
          columns={columns}
          components={{ icons: customIcons }}
          options={{
            onRowClick: (rowData, rowMeta) => {
              const index = 10 * currentPage + rowMeta.rowIndex;
              setIsShowDetail(true);
              setDataDetail(data[index]);
              console.log("... record ", data[index]);
            },
            selectableRows: "multiple",
            selectableRowsHideCheckboxes: true,
            selectableRowsOnClick: false,
            // fixedHeader:true
            responsive: "standard",
            textLabels: {
              body: {
                noMatch: t("recurringPayments.noMatch"),
              },
              toolbar: {
                search: `${t("bankAccounts.muiDataTable.toolBarIconsToolTips.search")}`,
                downloadCsv: `${t("bankAccounts.muiDataTable.toolBarIconsToolTips.downloadCsv")}`,
                print: `${t("bankAccounts.muiDataTable.toolBarIconsToolTips.print")}`,
                viewColumns: `${t("bankAccounts.muiDataTable.toolBarIconsToolTips.viewColumns")}`,
                filterTable: `${t("bankAccounts.muiDataTable.toolBarIconsToolTips.filterTable")}`,
              },
            },
            customFooter: (
              count,
              page,
              rowsPerPage,
              changeRowsPerPage,
              changePage
            ) => (
              <CustomPagination
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                onChangePage={changePage}
                setCurrentPage={setCurrentPage}
              />
            ),
          }}
        />
      </ThemeProvider>
      {/* </Card> */}
      {/* </Col> */}
      {/* </Row> */}

      {/* details modal */}

      <Modal
        show={detailsShow}
        onHide={handleCloseDetails}
        backdrop="static"
        keyboard={false}
        width={"100%"}
        scrollable
        size="lg"
        dialogClassName="my-modal"
      >
        <ModalHeader
          title={t("paymentHistoryModal.title")}
          onClose={handleCloseDetails}
        />
        <Modal.Body>
          <div className="details-header">
            <ThemeProvider theme={detailsTheme}>
              <MUIDataTable
                title={""}
                data={selectedDetail}
                columns={beneficiariesHeaders}
                components={{ icons: customIcons }}
                options={{
                //   serverSide: true,
                  selectableRows: "single",
                  selectableRowsHideCheckboxes: true,
                  selectableRowsOnClick: false,
                  filter: false,
                  print: false,
                  viewColumns: false,
                  download: false,
                  responsive: "standard",
                  search: true,
                  sort:false,
                  // onRowClick: (rowData, rowMeta) => {
                  // },
                  // onSearchChange: (searchText) => { dataTableSearchChange(searchText) },
                  customFooter: () => null,
                  setRowProps: () => {
                    return {
                      style: { cursor: "pointer" },
                    };
                  },
                }}
              />
            </ThemeProvider>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            border: "none",
          }}
        >
          {/* <CustomPagination
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            onChangePage={changePage}
            setCurrentPage={setCurrentPage}
            
          />
            */}
        </Modal.Footer>
      </Modal>
    </>
  );
};
