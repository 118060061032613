import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Modal, ModalFooter } from "react-bootstrap";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CurrencyFlag from "react-currency-flags";
import { useTranslation } from "react-i18next";
import "../../index.css"
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { FormattedCard, SelectedBusinessAccount, LoadCardData, AutoTopupConfigData } from "../../interface";
import BasicButton from "../../../../components/shared/Buttons/Basic";
import InputTypeText from "../../../../components/shared/Input/Basic/InputTypeText";
import AutoTopUpIcon from "../../../../assets/images/cardAssets/auto-topup.svg";
import Switch from "@mui/material/Switch";
import Swal from "sweetalert2";
import { CardAutoTopUpTogglePayload, CardAutoTopUpToggleResponse } from "../../../../redux/toolkit/cards/interface";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { cardAutoTopupToggle, getAllCards } from "../../../../redux/toolkit/cards/operation";
import { ErrorImage, SuccessImage } from "../../../../constants/Images";
import { GoGear } from "react-icons/go";
import { AiOutlineArrowLeft } from "react-icons/ai";
import cardSelectors from "../../../../redux/toolkit/cards/selector";
import { getCardAccountBalance } from "../../../../redux/toolkit/accountBalances/operation";


const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  // iconColor: 'green',
  customClass: {
    popup: 'colored-toast',
  },
  showConfirmButton: false,
  timer: 1500,
})

const AddMoneyModal = (props: {
  showAddMoneyModal: boolean,
  loadCardData: LoadCardData,
  setLoadCardData: React.Dispatch<React.SetStateAction<LoadCardData>>,
  setShowAddMoneySummary: React.Dispatch<React.SetStateAction<boolean>>,
  setShowAddMoney: React.Dispatch<React.SetStateAction<boolean>>,
  handleClose: () => void,
  selectedBusinessAcc: SelectedBusinessAccount;
  setSelectedBusinessAcc: React.Dispatch<React.SetStateAction<SelectedBusinessAccount>>;
  selectedCard: FormattedCard;
}) => {
  const { showAddMoneyModal, loadCardData, setLoadCardData, setShowAddMoney, setShowAddMoneySummary, handleClose, setSelectedBusinessAcc, selectedBusinessAcc, selectedCard } = props;
  const accountSelector: any = useSelector(
    (state: RootState) => state.getAllAccounts.data
  );
  const [isAutoTopUpOn, setIsAutoTopUpOn] = useState<boolean>(selectedCard.autoTopupEnabled);
  const [configureAutoTopup, setConfigureAutoTopup] = useState<boolean>(false);
  const prevConfigureAutoTopup = useRef(configureAutoTopup);
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const cardAutoTopupSelector = useSelector(cardSelectors.cardAutoTopupSelect);
  const [autoTopUpConfigData, setAutoTopUpConfig] = useState<AutoTopupConfigData>({
    autoTopupAmount: selectedCard.autoTopupAmount,
    minimumBalance: selectedCard.minimumBalance,
  });
  const BalanceSelector: any = useSelector(
    (state: RootState) => state.getAllAccountBalance.data
  );
  const [balanceError, setBalanceError] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === "amount" || name === "minimumBalance" || name === "autoTopupAmount") {
      // Handle empty input directly
      if (value === "") {
        setLoadCardData({ ...loadCardData, [name]: undefined });
        return;
      }

      // Remove non-numeric and non-decimal characters
      const numericValue = value.replace(/[^\d.]/g, '');

      // Remove leading zeros except for the first character if it's a decimal point
      let cleanedValue = numericValue.replace(/^0+(?=\d)/, '');

      // Ensure there is at most one decimal point
      const decimalCount = cleanedValue.split('.').length - 1;
      if (decimalCount > 1) {
        cleanedValue = cleanedValue.replace(/\.([^.]*)$/, '$1');
      }

      // Limit the length of the number to 10 digits
      const limitedLengthValue = cleanedValue.slice(0, 10);

      // Convert the value to a number
      const numVal = limitedLengthValue !== '' ? Number(limitedLengthValue) : undefined;

      // Check if the amount exceeds the balance
      if (numVal && name === "amount") {
        const currentBalance = BalanceSelector?.data?.data?.float || 0;

        if (numVal > currentBalance) {
          setBalanceError(true);
        } else {
          setBalanceError(false);
        }
      }

      setLoadCardData({ ...loadCardData, [name]: numVal });
    } else {
      setLoadCardData({ ...loadCardData, [name]: value });
    }
  };

  const handleAutoTopUpConfigChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === "minimumBalance" || name === "autoTopupAmount") {
      // Handle empty input directly
      if (value === "") {
        setAutoTopUpConfig({ ...autoTopUpConfigData, [name]: undefined });
        return;
      }

      // Remove non-numeric and non-decimal characters
      const numericValue = value.replace(/[^\d.]/g, '');

      // Remove leading zeros except for the first character if it's a decimal point
      let cleanedValue = numericValue.replace(/^0+(?=\d)/, '');

      // Ensure there is at most one decimal point
      const decimalCount = cleanedValue.split('.').length - 1;
      if (decimalCount > 1) {
        cleanedValue = cleanedValue.replace(/\.([^.]*)$/, '$1');
      }

      // Limit the length of the number to 10 digits
      const limitedLengthValue = cleanedValue.slice(0, 10);

      // Convert the value to a number
      const numVal = limitedLengthValue !== '' ? Number(limitedLengthValue) : undefined;

      setAutoTopUpConfig({ ...autoTopUpConfigData, [name]: numVal });
    } else {
      setAutoTopUpConfig({ ...autoTopUpConfigData, [name]: value });
    }
  }

  const handleAutoTopUp = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAutoTopUpOn(event.target.checked);
  };

  const handleSubmit = (event: any) => {
    setShowAddMoneySummary(true);
    setShowAddMoney(false);
  };

  const handleConfigureAutoTopup = (event: any) => {
    const togglePayload: CardAutoTopUpTogglePayload = {
      userId: localStorage.getItem('userId')!,
      cardID: selectedCard.cardId,
      enableAutoTopup: isAutoTopUpOn,
      autoTopupAmount: autoTopUpConfigData.autoTopupAmount,
      minimumBalance: autoTopUpConfigData.minimumBalance,
    };
    dispatch(cardAutoTopupToggle(togglePayload))
      .unwrap()
      .then((resp: CardAutoTopUpToggleResponse) => {
        Toast.fire({
          icon: 'success',
          title: 'Configuration updated successfully!',
          iconColor: 'green'
        })
        dispatch(getAllCards({ userId: localStorage.getItem('userId')! }))
          .then(() => {
            setConfigureAutoTopup(false);
          });
      })
      .catch((err) => {
        console.log(err);
        Toast.fire({
          icon: 'error',
          title: 'Error updating configuration!',
          iconColor: 'red',
        }).then(() => {
          // setAutoTopUpConfig({ minimumBalance: 0, autoTopupAmount: 0 });
          // handleClose();
        });
      });
  }

  useEffect(() => {
    if (accountSelector?.data?.data) {
      const matchingAccount = accountSelector.data.data.find((acc: any) => acc.accB4BUUID === selectedCard?.accB4BUUID);
      if (matchingAccount) {
        setSelectedBusinessAcc(matchingAccount)
        // console.log("matching Account: ", matchingAccount)
        dispatch(getCardAccountBalance(selectedCard?.accB4BUUID as any))
      } else {
        setSelectedBusinessAcc({})
      }
    }
  }, [accountSelector]);

  useEffect(() => {
    prevConfigureAutoTopup.current = configureAutoTopup;
  }, [configureAutoTopup]);

  return (
    <Modal
      show={showAddMoneyModal}
      onHide={() => {
        setLoadCardData({ amount: 0, description: "" });
        setAutoTopUpConfig({ minimumBalance: 0, autoTopupAmount: 0 });
        return handleClose();
      }}
      centered
      style={{ marginTop: '8px' }}
    >
      <Modal.Header style={{ borderBottom: "0px" }}>
        <div style={{ display: "flex", alignItems: 'center' }}>
          {configureAutoTopup && <div className="goBackIcon" style={{ display: "flex" }}
            onClick={() => {
              setConfigureAutoTopup(false);
            }}
          >
            <AiOutlineArrowLeft />
          </div>}
          <Modal.Title>{configureAutoTopup ? t("addMoneyModal.configureAutoTopUp") : t("addMoneyModal.addMoney")}</Modal.Title>
        </div>
        <IconButton
          style={{ backgroundColor: '#006ff4', color: 'white', width: '22px', height: '22px' }}
          onClick={() => {
            setLoadCardData({ amount: 0, description: "" });
            setAutoTopUpConfig({ minimumBalance: 0, autoTopupAmount: 0 });
            return handleClose();
          }}
        >
          <CloseIcon style={{ fontSize: "1.2rem" }} />
        </IconButton>
      </Modal.Header  >
      <Modal.Body style={{ borderTop: "0px" }}>
        {!configureAutoTopup &&
          <div className="mb-4">
            <p style={{ margin: 0, fontWeight: "600", marginBottom: "10px" }}>{t("addMoneyModal.businessAccount")}</p>
            <Col className="col-12 p-2"
              style={{
                border: '1px solid #D7F0FF',
                borderRadius: "10px",
              }}>
              {selectedBusinessAcc.accountName ?
                <p style={{ margin: 0 }} className="currencyFlag">
                  {selectedBusinessAcc.accountName}
                </p>
                :
                <p style={{ margin: 0, color: "gray" }}>
                  --- {t("addMoneyModal.noCorrespondingAccounts")} ---
                </p>
              }
            </Col>

            <p style={{ margin: 0, fontWeight: "600", marginTop: "20px" }}>{t("addMoneyModal.cardDetails")}</p>
            <div className="addMoney-AmountToTopUp">
              <Col className="p-1">
                <p style={{ fontSize: "14px", margin: 0, color: "#ADB6C0" }}>{t("addMoneyModal.name")}</p>
                <Col className="p-2" style={{
                  border: '1px solid #D7F0FF',
                  borderRadius: "10px",
                }}>
                  {selectedCard.holderFirstName} {selectedCard.holderLastName}
                </Col>
              </Col>

              <Col className="p-1">
                <p style={{ fontSize: "14px", margin: 0, color: "#ADB6C0" }}>{t("addMoneyModal.last4digits")}</p>
                <Col className="p-2" style={{
                  border: '1px solid #D7F0FF',
                  borderRadius: "10px",
                }}>
                  {selectedCard.maskedCardNumber}
                </Col>
              </Col>

              <Col className="p-1">
                <p style={{ fontSize: "14px", margin: 0, color: "#ADB6C0" }}>{t("addMoneyModal.cardType")}</p>
                <Col className="p-2" style={{
                  border: '1px solid #D7F0FF',
                  borderRadius: "10px",
                }}>
                  {selectedCard.cardType.split(" ")[0]}
                </Col>
              </Col>
            </div>

            <p style={{ margin: 0, fontWeight: "600", marginTop: "20px" }}>{t("addMoneyModal.amountToTopUp")}</p>
            <div className="addMoney-AmountToTopUp mt-2">
              <Col className="col-3"
                style={{
                  border: '1px solid #D7F0FF',
                  borderRadius: "10px",
                }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    height: '100%'
                  }}
                >
                  <CurrencyFlag currency={selectedCard.currency} />
                  <p style={{ margin: 0 }} className="currencyFlag">{selectedCard.currency}</p>
                </div>
              </Col>

              <Col className="col-9">
                <div className="addMoney-AmountToTopUp-AmountInput">
                  <InputTypeText
                    compulsoryValue
                    type="number"
                    inputContainerMargins="0"
                    placeholder={t("bankAccountCards.amount")}
                    name="amount"
                    value={loadCardData.amount}
                    onChange={handleChange}
                  />
                </div>
              </Col>
            </div>

            {balanceError &&
              <p style={{ color: "red" }}>{t("errorMsgs.amountGreaterThanBalance")}</p>
            }

            <p className="mb-2" style={{ margin: 0, fontWeight: "600", marginTop: "20px" }}>{t("addMoneyModal.description")}</p>
            <InputTypeText
              type="text"
              inputContainerMargins="0"
              placeholder={t("addMoneyModal.descriptionPlaceholder")}
              value={loadCardData.description}
              name="description"
              onChange={handleChange}
              maxLength={250}
            />
          </div>
        }
        <div
          className={`${configureAutoTopup ? 'slide' : prevConfigureAutoTopup.current && !configureAutoTopup ? 'slide-back' : ''}`}
          style={{
            display: "flex",
            alignItems: "center",
            padding: "14px 14px",
            boxShadow: "0px 0px 6px 2px #001B3A0F",
            borderRadius: "10px",
            justifyContent: "space-between"
          }}
        >
          <Col className="mainBox-icon col-2 col-md-3 col-lg-3">
            <img src={AutoTopUpIcon} alt="Auto Top-Up" style={{ width: "24px", height: "24px" }} />
          </Col>
          <Col
            className="col-5 col-md-6 col-lg-6"
            style={{ fontWeight: 600, fontSize: "14px", color: "#231F20" }}
          >
            {t("addMoneyModal.autoTopUp")}
          </Col>
          <div className="col-4 d-flex justify-content-end align-items-center">
            <Col className=" col-4 d-flex justify-content-end ">
              <Switch
                disabled={!configureAutoTopup}
                checked={configureAutoTopup ? isAutoTopUpOn : selectedCard.autoTopupEnabled}
                onChange={handleAutoTopUp}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Col>
            <Col className=" col-6 d-flex justify-content-center ">
              <GoGear className="fs-4" style={{ cursor: 'pointer' }} onClick={() => setConfigureAutoTopup(!configureAutoTopup)} />
            </Col>
          </div>
        </div>
        {configureAutoTopup &&
          <>
            <div className={`mt-3 ${configureAutoTopup ? 'fade-in' : ''}`}>
              <p style={{ fontWeight: 600 }}>{t("addMoneyModal.balanceGoesBelow")}</p>
              <div className="addMoney-AmountToTopUp">
                <Col
                  className="col-3"
                  style={{
                    border: "1px solid #D7F0FF",
                    borderRadius: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <CurrencyFlag currency={selectedCard.currency} />
                    <p style={{ margin: 0 }} className="currencyFlag">
                      {selectedCard.currency}
                    </p>
                  </div>
                </Col>

                <Col className="col-9">
                  <div className="addMoney-AmountToTopUp-AmountInput">
                    <InputTypeText
                      compulsoryValue
                      type="number"
                      inputContainerMargins="0"
                      placeholder="Amount"
                      name="minimumBalance"
                      value={autoTopUpConfigData.minimumBalance}
                      onChange={handleAutoTopUpConfigChange}
                      disabled={!isAutoTopUpOn}
                    />
                  </div>
                </Col>
              </div>
            </div>
            <div className={`mt-3 ${configureAutoTopup ? 'fade-in' : ''}`}>
              <p style={{ fontWeight: 600 }}>{t("addMoneyModal.autoTopUpFor")}</p>
              <div className="addMoney-AmountToTopUp">
                <Col
                  className="col-3"
                  style={{
                    border: "1px solid #D7F0FF",
                    borderRadius: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <CurrencyFlag currency={selectedCard.currency} />
                    <p style={{ margin: 0 }} className="currencyFlag">
                      {selectedCard.currency}
                    </p>
                  </div>
                </Col>

                <Col className="col-9">
                  <div className="addMoney-AmountToTopUp-AmountInput">
                    <InputTypeText
                      compulsoryValue
                      type="number"
                      inputContainerMargins="0"
                      placeholder="Amount"
                      name="autoTopupAmount"
                      value={autoTopUpConfigData.autoTopupAmount}
                      onChange={handleAutoTopUpConfigChange}
                      disabled={!isAutoTopUpOn}
                    />
                  </div>
                </Col>
              </div>
            </div>
          </>
        }
      </Modal.Body>
      <ModalFooter style={{ borderTop: "0px", padding: "15px 30px" }}>
        {!configureAutoTopup ?
          <BasicButton
            type="button"
            onClick={handleSubmit}
            disabled={!(
              selectedBusinessAcc.accountName &&
              loadCardData.amount !== undefined &&
              loadCardData.amount !== 0
            )
            }
            text={t("addMoneyModal.addMoney")}
          />
          :
          <BasicButton
            type="button"
            isLoading={cardAutoTopupSelector.status === "loading"}
            onClick={handleConfigureAutoTopup}
            disabled={cardAutoTopupSelector.status === "loading" || !(selectedBusinessAcc.accountName && autoTopUpConfigData.minimumBalance !== undefined && autoTopUpConfigData.minimumBalance !== 0 && autoTopUpConfigData.autoTopupAmount !== 0 && autoTopUpConfigData.autoTopupAmount !== undefined && autoTopUpConfigData.autoTopupAmount !== null && autoTopUpConfigData.minimumBalance !== null)} // added checks for null for cards that haven't configured autoTopupEnabled, minimumBalance and autoTopUpAmount yet.
            text={t("addMoneyModal.saveAutoTopUpConfiguration")}
          />
        }
      </ModalFooter>
    </Modal >
  )
};

export default AddMoneyModal;